import ReducerConstants from "../../config/ReduxConstant";

const INITIAL_STATE = {
    isClassModeActive: false,
    toggleShowStudentLoginInstructionsValue: {fromPage: null, section: null, visible: false},
    SET_SHOW_COMMON_POPUP_PORTAL: false,
    commonPopupPortalState: null,
    summerLearningActive: false,
    isKlassAc1: false,
    isNewCoteacherAdded: false,
    rosterTableDataPrepared: false,
    isLoginViaQrCoachmarkVisible: false,
    mandatoryActivityAssigned: false,
    showOverlaysPortal: false,
    reviewTabVisitedOnce: false,
    totalOngoingTasks: 0,
    changeTopicCoachmarkData: null,
    overlayRenderedType: null,
    isChangeFoundInClassSettings: false,
    threeAssignmentsAssigned: false,
    assignmentSuccessFromStudentSelection: null,
    springboardClosureActive: null,
    isIADashboard: false,
    showReturnTeacherCoachmark: false,
    showMilestoneUiChanges: false,
    showMilestoneUiChangesMigrationExperience: false,
    displayIATransitionCoachmark: false,
    displayIATransitionIntroBanner: false,
    isFtueScreenSeen: false,
    forceShowIAIntroBanner: false,
    takeGuidedTourClicked: false,
    showKlassTransferCoachmark: false,
    klassTransferInfo: false,
}

export const appFlags = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ReducerConstants.SET_CLASS_MODE:
            return {
                ...state,
                isClassModeActive: action.payload
            };
        case ReducerConstants.TOGGLE_IA_DASHBOARD:
            return {
                ...state,
                isIADashboard: action.payload
            };

        case "TOGGLE_STUDENT_LOGIN_INSTRUCTIONS_POPUP":
            return {
                ...state,
                toggleShowStudentLoginInstructionsValue: action.payload
            };
        
        case "SET_SHOW_DISCOVERY_POPUP_PORTAL":
            return {
                ...state,
                showDiscoveryPopupPortal: action.payload
            }

        case "SET_SHOW_COMMON_POPUP_PORTAL": 
            return {
                ...state,
                commonPopupPortalState: action.payload
            }   

        case "SET_SPLASH_COMMUNITY_LOGGED_IN": 
            return {
                ...state,
                    splashCommunityStates: action.payload
            }

        case ReducerConstants.SET_NUDGE_POPUP_STATE: 
            return {
                ...state,
                nudgePopupState: action.payload
            }

        case ReducerConstants.SET_FEEDBACK_NUDGE_DATA:
            return  {
                ...state,
                feedbackNudgeData: action.payload
            }
        case "SET_SUMMER_LEARNING_ACTIVE": 
            return {
                ...state,
                summerLearningActive: action.payload
            }
        
        case "SET_IS_KLASS_AC1":
            return {
                ...state,
                isKlassAc1: action.payload
            }
        case "TOGGLE_ADDED_COTEACHER_COACHMARK":
            return {
                ...state,
                isNewCoteacherAdded: action.payload
            }
        case "FEEDBACK_POPUP_CLOSED":
            return {
                ...state,
                feedbackPopupClosed: action.payload
            }
        
        case "ROSTER_TABLE_DATA_PREPARED":
            return {
                ...state,
                rosterTableDataPrepared: action.payload
            }

        case "CHANGE_TOPIC_COACHMARK_DATA":
            return {
                ...state,
                changeTopicCoachmarkData: action.payload
            }

        case "TOGGLE_LOGIN_VIA_QR_COACHMARK":
            return {
                ...state,
                isLoginViaQrCoachmarkVisible: action.payload
            }
            
        case "TOGGLE_MANDATORY_ACTIVITY_ASSIGNED":
            return {
                ...state,
                mandatoryActivityAssigned: action.payload
            }

        case ReducerConstants.TOGGLE_OVERLAYS_PORTAL:
            return {
                ...state,
                showOverlaysPortal: action.payload
            }
        
        case ReducerConstants.TOGGLE_REVIEW_TAB_VISITED:
            return {
                ...state,
                reviewTabVisitedOnce: action.payload
            }
            
        case "SET_TOTAL_ONGOING_TASKS":
            return {
                ...state,
                totalOngoingTasks: action.payload
            }
        
        case "TOGGLE_ONBOARDING_LOADER_ACTIVE":
            return {
                ...state,
                isOnboardingLoaderActive: action.payload
            }
        
            case ReducerConstants.SET_OVERLAY_RENDERED_TYPE:
            return {
                ...state,
                overlayRenderedType: action.payload
            }
            
        case ReducerConstants.TOGGLE_CHANGES_FOUND_IN_CLASS_SETTINGS:
            return {
                ...state,
                isChangeFoundInClassSettings: action.payload
            }
        case ReducerConstants.TOGGLE_THREE_ASSIGNMENTS_ASSIGNED:
            return {
                ...state,
                threeAssignmentsAssigned: action.payload
            }
        case ReducerConstants.ASSIGNMENT_SUCCESS_FROM_STUDENT_SELECTION:
            return {
                ...state,
                assignmentSuccessFromStudentSelection: action.payload
            }
        
        case ReducerConstants.TOGGLE_SPRINGBOARD_CLOSURE:
            return {
                ...state,
                springboardClosureActive: action.payload
            }

        case ReducerConstants.TOGGLE_RETURN_TEACHER_COACHMARK:
            return {
                ...state,
                showReturnTeacherCoachmark: action.payload
            }
        case ReducerConstants.TOGGLE_KLASS_TRANSFER_COACHMARK:
            return {
                ...state,
                showKlassTransferCoachmark: action.payload
            }
        case ReducerConstants.KLASS_TRANSFER_INFO:
            return {
                ...state,
                klassTransferInfo: action.payload
            }
        case 'SHOW_MILESTONE_UI_CHANGES':
            return {
                ...state,
                showMilestoneUiChanges: action.payload
            }
        case 'SHOW_MILESTONE_UI_CHANGES_MIGRATION_EXP':
            return {
                ...state,
                showMilestoneUiChangesMigrationExperience: action.payload
            }
        case ReducerConstants.TOGGLE_IA_TRANSITION_COACHMARK:
            return {
                ...state,
                displayIATransitionCoachmark: action.payload
            }
        case ReducerConstants.FORCE_SHOW_IA_INTRO_BANNER:
            return {
                ...state,
                forceShowIAIntroBanner: action.payload
            } 
        case ReducerConstants.IS_FTUE_SCREEN_SEEN:
            return {
                ...state,
                isFtueScreenSeen: action.payload
            }
        case ReducerConstants.UPDATE_APP_FLAGS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}