import { dispatchAvoEvent } from "avo/index";
import { AVO_EVENT_NAMES } from "../avo/eventNames";

export const sendEventUsingAvo = (props: any = {}) => {
    const initParams = {
        //@ts-ignore
        klassGrade: window.KLASS_GRADE?.code ?? null, //@ts-ignore
        currentGrade: window.KLASS_GRADE?.code ?? null, //@ts-ignore
        subjectCode: window.KLASS_DETAIL?.id ? "Math" : '', //@ts-ignore
        klass: window.KLASS_DETAIL,
        userType: "Parent"
    };

    dispatchAvoEvent(props, initParams);
};

// signup events
// logged out or login event
export const viewedPtlParentSignupScreenEvent = ({ referrerPage, invitationSource } : { referrerPage : string, invitationSource: string }) => {
    // @ts-ignore
    window.isLoggedInUser ? 
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.viewedPtlParentSignupScreen,
        props: {
            mathGrade: localStorage.getItem("mathGrade")?.toLowerCase(), // based on student id,
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
     :
    trackEventsForGuests("Viewed PTL Parent Signup Screen", { 
        "custom_user_type": "guest_ptl_parent",
        "Math Grade" : localStorage.getItem("mathGrade")?.toLowerCase(), // based on student id
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage
      }, { send_mixpanel: true }
    )
}

// logged out or login event
export const interactedPtlParentSignupScreenEvent = ({ clickedOn = "", interactionDetails= "", referrerPage, invitationSource } : { clickedOn: string; interactionDetails?: string, referrerPage : string, invitationSource: string }) => {
    // @ts-ignore
    window.isLoggedInUser ? 
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.interactedPtlParentSignupScreen,
        props: {
            mathGrade: localStorage.getItem("mathGrade")?.toLowerCase(), // based on student id,
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
            clickedOn,
            interactionDetails
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
     :
    trackEventsForGuests("Interacted PTL Parent Signup Screen", { 
        "custom_user_type": "guest_ptl_parent",
        "Math Grade" : localStorage.getItem("mathGrade"), 
        "Clicked On": clickedOn,
        "Interaction Details": interactionDetails,
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage
        }, { send_mixpanel: true }
    );
}

export const viewedErrorOnPtlParentSignupScreenEvent = ({ errorMessage = "", referrerPage, invitationSource } : { errorMessage: string, referrerPage: string, invitationSource: string }) => {
    // @ts-ignore
    window.isLoggedInUser ? 
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.viewedErrorOnPtlParentSignupScreen,
        props: {
            mathGrade: localStorage.getItem("mathGrade")?.toLowerCase(), // based on student id,
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
            errorMessage
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
     :
    trackEventsForGuests("Viewed Error on PTL Parent Signup Screen", { 
        "custom_user_type": "guest_ptl_parent",
        "Math Grade" : localStorage.getItem("mathGrade")?.toLowerCase(), 
        "Error Message": errorMessage,
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage
        }, { send_mixpanel: true }
    );
}


// login events
export const viewedLoginViaOtpByClassroomLinkedParentEvent = ({ fromPage = "", referrerPage, invitationSource } : { fromPage: string, referrerPage : string, invitationSource: string }) => {
    trackEventsForGuests("Viewed Login via OTP by Classroom Linked Parent", {
        "custom_user_type": "guest_ptl_parent",
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage,
        "From Page": fromPage
      }, { send_mixpanel: true }
    );
}

export const interactedLoginViaOtpByClassroomLinkedParentEvent = ({clickedOn, otpEntered, interactionDetails, referrerPage, invitationSource }: { clickedOn: string, otpEntered: boolean; interactionDetails?: string, referrerPage : string, invitationSource: string }) => {
    trackEventsForGuests("Interacted Login via OTP by Classroom Linked Parent", { 
        "custom_user_type": "guest_ptl_parent",
        "Clicked On" : clickedOn,
        "OTP Entered": otpEntered ? "1" : "0",
        "Interaction Details": interactionDetails,
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage
      }, { send_mixpanel: true }
    );
}

export const ptlParentLoginAttemptedEvent = ({ isEmailUpdated = false, loginMethod= "", referrerPage, invitationSource } : { isEmailUpdated: boolean, loginMethod: string, referrerPage : string, invitationSource: string}) => {
    trackEventsForGuests("PTL Parent Login Attempted", { 
        "custom_user_type": "guest_ptl_parent",
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage,
        "Login Method": loginMethod,
        "Is Email Updated" : isEmailUpdated ? "1" : "0"
      }, { send_mixpanel: true }
    );
}

export const ptlParentLoginFailedEvent = ({ isEmailUpdated = false, loginMethod= "", referrerPage, invitationSource, errorMessage } : { isEmailUpdated: boolean, loginMethod: string, referrerPage : string, invitationSource: string, errorMessage: string }) => {
    trackEventsForGuests("PTL Parent Login Failed", { 
        "custom_user_type": "guest_ptl_parent",
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage,
        "Login Method": loginMethod,
        "Is Email Updated" : isEmailUpdated ? "1" : "0",
        "Error Message": errorMessage
      }, { send_mixpanel: true }
    );
}

export const interactedPtlParentLoginScreenEvent = ({ clickedOn = "", interactionDetails = "", referrerPage, invitationSource } : { clickedOn: string; interactionDetails?: string, referrerPage : string, invitationSource: string }) => {
    trackEventsForGuests("Interacted PTL Parent Login Screen", { 
        "custom_user_type": "guest_ptl_parent",
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage,
        "Clicked On": clickedOn,
        "Interaction Details": interactionDetails
      }, { send_mixpanel: true }
    );
}

export const viewedPtlParentLoginScreenEvent = ({ fromPage = "", ptlFlow = "", referrerPage, invitationSource } : { fromPage: string; ptlFlow: string, referrerPage : string, invitationSource: string }) => {
    trackEventsForGuests("Viewed PTL Parent Login Screen", { 
        "custom_user_type": "guest_ptl_parent",
        "Invitation Source": invitationSource,
        "Referrer Page": referrerPage,
        "From Page": fromPage,
        "PTL Flow": ptlFlow
      }, { send_mixpanel: true }
    );
}

// merge screen events
export const viewedPtlStudentLinkedSuccessfullyPopupEvent = ({ linkedStudentCount, optionsSelected, grade = "", referrerPage, invitationSource } : { linkedStudentCount:string;  optionsSelected: Array<string>, grade: string, referrerPage : string, invitationSource: string }) => {
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.viewedPtlStudentLinkedSuccessfullyPopup,
        props: {
            mathGrade: grade?.toLowerCase(),
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
            linkedStudentCount,
            optionsSelected
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
}

export const interactedAddreplaceStudentProfileScreenEvent = ({clickedOn, linkedStudentCount, grade = "", referrerPage, invitationSource } : {clickedOn: string; linkedStudentCount: string; grade: string, referrerPage : string, invitationSource: string }) => {
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.interactedAddreplaceStudentProfileScreen,
        props: {
            mathGrade: grade?.toLowerCase(),
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
            linkedStudentCount,
            clickedOn,
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
}

export const viewedAddreplaceStudentProfileScreenEvent = ({ linkedStudentCount, optionsViewed, grade = "", referrerPage, invitationSource } : { linkedStudentCount:string;  optionsViewed: Array<string>; grade: string, referrerPage : string, invitationSource: string }) => {
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.viewedAddreplaceStudentProfileScreen,
        props: {
            mathGrade: grade?.toLowerCase(),
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
            linkedStudentCount,
            optionsViewed,
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
}

export const ptlLinkingAttemptedEvent = ({ linkedStudentCount, optionsSelected, grade = "", referrerPage, invitationSource } : { linkedStudentCount: string; optionsSelected: Array<string>, grade: string, referrerPage : string, invitationSource: string }) => {
    sendEventUsingAvo({
        eventName: AVO_EVENT_NAMES.ptlLinkingAttempted,
        props: {
            mathGrade: grade?.toLowerCase(),
            mode: "home", // overriden later
            invitationSource: invitationSource,
            referrerPage: referrerPage,
            ptlFlow: "PTL student linking",
            linkedStudentCount,
            optionsSelected
        },
        teacherCoreProperties: false, 
        userCoreProperties: true, 
        studentCoreProperties: false
    })
}