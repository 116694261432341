// reducers

const ReducerConstants = Object.freeze({
    SET_CONTENT_GROUPS : "SET_CONTENT_GROUP_DATA",
    SET_CURRICULUM_DROPDOWN: "SET_CURRICULUM_DROPDOWN",
    SET_STUDENT_STUDENT_LIST:  "SET_STUDENT_STUDENT_LIST",
    UPDATE_TEACHER_SCHOOL: "UPDATE_TEACHER_SCHOOL",
    SET_CLASS_MODE: "SET_CLASS_MODE",
    SET_KLASS_SELECTED_PROFILE_DATA: "SET_KLASS_SELECTED_PROFILE_DATA",
    SET_SELECTED_GRADE_CODE: "SET_SELECTED_GRADE_CODE",
    TOGGLE_YIR_POPUP: "TOGGLE_YIR_POPUP",
    SET_ASSIGNED_FACT_PRACTICES: "SET_ASSIGNED_FACT_PRACTICES",
    SET_ASSIGNED_FACT_PRACTICES_TO_KLASS: "SET_ASSIGNED_FACT_PRACTICES_TO_KLASS",
    SET_KLASS_STUDENTS_DETAILS: "SET_KLASS_STUDENTS_DETAILS",
    SET_FACTS_SEED_DATA: "SET_FACTS_SEED_DATA",
    SET_FACTS_REPORTING_DATA: "SET_FACTS_REPORTING_DATA",
    SET_CURRICULUM_SEED_DATA: "SET_CURRICULUM_SEED_DATA",
    SET_CONTENT_WISE_LO_SEED_DATA: "SET_CONTENT_WISE_LO_SEED_DATA",
    SHOW_CUSTOM_TEST_REPORTS: "SHOW_CUSTOM_TEST_REPORTS",
    SAVE_CUSTOM_TEST_REPORTS: "SAVE_CUSTOM_TEST_REPORTS",
    SAVE_PAST_CUSTOM_TEST_REPORTS: "SAVE_PAST_CUSTOM_TEST_REPORTS",
    SET_SURVEY_SEED_DATA: "SET_SURVEY_SEED_DATA",
    SET_FEEDBACK_NUDGE_DATA: "SET_FEEDBACK_NUDGE_DATA",
    AB_TEST_DATA: "AB_TEST_DATA",
    SET_WORKSHEET_VALUE: "SET_WORKSHEET_VALUE",
    SET_ONBOARDING_COMPLETION_DATE: "SET_ONBOARDING_COMPLETION_DATE",
    SET_NUDGE_POPUP_STATE: "SET_NUDGE_POPUP_STATE",
    SET_STUDENTS_LIST: "SET_STUDENTS_LIST",
    SET_FEEDBACK_SEED_DATA: "SET_FEEDBACK_SEED_DATA",
    SET_FEEDBACK_DATA: "SET_FEEDBACK_DATA",
    MODIFY_COURSES_SEED_DATA: "MODIFY_COURSES_SEED_DATA",
    SET_FEEDBACK_ELIGIBILITY: "SET_FEEDBACK_ELIGIBILITY",
    SET_CONTEST_DATA: "SET_CONTEST_DATA",
    TOGGLE_OVERLAYS_PORTAL: "TOGGLE_OVERLAYS_PORTAL",
    SET_TOTAL_ONGOING_TASKS: "SET_TOTAL_ONGOING_TASKS",
    SET_TEACHER_REFERRALS: "SET_TEACHER_REFERRALS",
    TOGGLE_REVIEW_TAB_VISITED: "TOGGLE_REVIEW_TAB_VISITED",
    SET_OVERLAY_RENDERED_TYPE: "SET_OVERLAY_RENDERED_TYPE",
    TOGGLE_CHANGES_FOUND_IN_CLASS_SETTINGS: "TOGGLE_CHANGES_FOUND_IN_CLASS_SETTINGS",
    TOGGLE_IS_CONTEST_LOADING: "TOGGLE_IS_CONTEST_LOADING",
    UPDATE_CONTEST_POPUP_DATA: "UPDATE_CONTEST_POPUP_DATA",
    TOGGLE_THREE_ASSIGNMENTS_ASSIGNED: "TOGGLE_THREE_ASSIGNMENTS_ASSIGNED",
    SET_FULL_SCREEN_DATA: "SET_FULL_SCREEN_DATA",
    ASSIGNMENT_SUCCESS_FROM_STUDENT_SELECTION: "ASSIGNMENT_SUCCESS_FROM_STUDENT_SELECTION",
    SET_ASSIGNMENT_SUCCESS_POPUP_DATA: "SET_ASSIGNMENT_SUCCESS_POPUP_DATA",
    TOGGLE_SPRINGBOARD_CLOSURE: "TOGGLE_SPRINGBOARD_CLOSURE",
    TOGGLE_IA_DASHBOARD: "TOGGLE_IA_DASHBOARD",
    TOGGLE_RETURN_TEACHER_COACHMARK: "TOGGLE_RETURN_TEACHER_COACHMARK",
    TOGGLE_IA_TRANSITION_COACHMARK: "TOGGLE_IA_TRANSITION_COACHMARK",
    IS_FTUE_SCREEN_SEEN: "IS_FTUE_SCREEN_SEEN",
    FORCE_SHOW_IA_INTRO_BANNER: "FORCE_SHOW_IA_INTRO_BANNER",
    UPDATE_APP_FLAGS: "UPDATE_APP_FLAGS",
    SET_GRADE_SEED_DATA : "SET_GRADE_SEED_DATA",
    SET_TEACHER_PROFILE: "SET_TEACHER_PROFILE",
    SET_SUMMER_LEARNING_ACTIVE: "SET_SUMMER_LEARNING_ACTIVE",
    SHOW_MILESTONE_UI_CHANGES: "SHOW_MILESTONE_UI_CHANGES",
    SHOW_MILESTONE_UI_CHANGES_MIGRATION_EXP: "SHOW_MILESTONE_UI_CHANGES_MIGRATION_EXP",
    SET_SELECTED_CLASS: "SET_SELECTED_CLASS",
    SET_KLASS_CURRICULUM: "SET_KLASS_CURRICULUM",
    TOGGLE_KLASS_TRANSFER_COACHMARK: "TOGGLE_KLASS_TRANSFER_COACHMARK",
    KLASS_TRANSFER_INFO: "KLASS_TRANSFER_INFO"
})

Object.freeze(ReducerConstants)
export default ReducerConstants;
