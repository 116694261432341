import { AppendQueryParams, GlobalSpinner } from '../common';
import * as abtestMapping from './ab_test_mapping';
import * as paths from '../../components/teacher_app_container/paths';
import {assetPath} from "../../utils/school-ui-helpers";
import HeaderLogoImage from "TEACHER_ASSETS/images/header/logo.png";
import ComponentLoaderGif from "TEACHER_ASSETS/images/common/loader.gif";
import OolzooImage from "TEACHER_ASSETS/images/teacher/dashboard/oolzoo.png";
import NoAssignmentImage from "TEACHER_ASSETS/images/teacher/no-assignment.png";
import SightWordsImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/imageSightWords.png";
import LeveledImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/imageLevelled.png";
import DecodeableImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/imageDecodable.png";
import PinkImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/swPatternPink.png";
import DarkgreenImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/swPatternDarkGreen.png";
import BlueImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/swPatternBlue.png";
import DarkPink from "TEACHER_ASSETS/images/teacher/assignment-landingPage/swPatternDarkPink.png";
import YellowImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/swPatternYellow.png";
import GreenImage from "TEACHER_ASSETS/images/teacher/assignment-landingPage/swPatternGreen.png";
import Child1Image from "TEACHER_ASSETS/images/avatars/child-1-thumb.png";
import Child2Image from "TEACHER_ASSETS/images/avatars/child-2-thumb.png";
import Child3Image from "TEACHER_ASSETS/images/avatars/child-3-thumb.png";
import Child4Image from "TEACHER_ASSETS/images/avatars/child-4-thumb.png";
import Child5Image from "TEACHER_ASSETS/images/avatars/child-5-thumb.png";
import Child6Image from "TEACHER_ASSETS/images/avatars/child-6-thumb.png";
import Child1Avatar from "TEACHER_ASSETS/images/avatars/child-1.png";
import Child2Avatar from "TEACHER_ASSETS/images/avatars/child-2.png";
import Child3Avatar from "TEACHER_ASSETS/images/avatars/child-3.png";
import Child4Avatar from "TEACHER_ASSETS/images/avatars/child-4.png";
import Child5Avatar from "TEACHER_ASSETS/images/avatars/child-5.png";
import Child6Avatar from "TEACHER_ASSETS/images/avatars/child-6.png";
import Star0Image from "TEACHER_ASSETS/images/teacher/assignment-landingPage/star-0.png";
import Star1Image from "TEACHER_ASSETS/images/teacher/assignment-landingPage/star-1.png";
import Star2Image from "TEACHER_ASSETS/images/teacher/assignment-landingPage/star-2.png";
import Star3Image from "TEACHER_ASSETS/images/teacher/assignment-landingPage/star-3.png";
import NoBookmarkImage from "TEACHER_ASSETS/images/worksheet/no-bookmark.png";
import NoSearchImage from "TEACHER_ASSETS/images/worksheet/no-search.png";
import { throwApiError, throwApiResposeReadingError } from '../../components/teacher_app_container/ErrorBoundary/APIError';
import { CommonMessages, EmailConstants } from '../../../utils/constants';
import { TeacherIcons } from './commonIcons';
import { deferTask } from '../../../common/common_utility';
import Store from '../../store';
import endpoints from "../../service/endpoints";
import { dispatchAvoEvent } from 'avo';


//This file contains any functions that are to be used across components in Left Nav flow

/**
 * This function will provide the common link to be used for routing
 *
 * @param {String} klassSlug - Klass identifier for currently selected class
 * @param {gradeCode} gradeCode - Currently selected grade code
 * @returns
 */
const generateCommonLinkPrefix = (klassSlug, gradeCode) => {
  return `/class/${klassSlug}/grades/${getFormattedGradeCode(gradeCode)}`
}

/**
 * This function is provides the mapping of items inside any navigation section in left/top navbar
 *
 * @param {String} klassSlug - Klass identifier for currently selected class
 * @param {gradeCode} gradeCode - Currently selected grade code
 * @param {navKey?} navKey - Navigation section for which mapping is to be fetched
 *
 * @returns {Object} - Navigation mapping containing icon and sub-level navigation items/sections
 *
 */
let commonLinkPrefix = (klassSlug, gradeCode) => `/class/${klassSlug}/grades/${getFormattedGradeCode(gradeCode)}`;

let fetchRouteNameFromPath = (path) => path.split("/")[path.split("/").length-1];

let fetchClassSlugFromPath = (path) => {
  let pathItems =   path.split("/");
  if(pathItems.includes("class")){
    return pathItems[pathItems.indexOf("class") + 1]
  }
  else return null
};

let fetchGradeCodeFromPath = (path) => {
  let pathItems =   path.split("/");
  if(pathItems.includes("grades")){
    return getGradeCodeFromFormattedGradeCode(pathItems[pathItems.indexOf("grades") + 1])
  }
  else return null
};

const getNavigationMapping = (klassSlug, gradeCode, navKey) => {
  let commonLinkPrefix = generateCommonLinkPrefix(klassSlug, gradeCode);

  let navigationMap = {
    "teacher_home": {
      "svg_icon": TeacherIcons.DashboardIcon,
      "display_name": "Dashboard",
      "name_slug": "teacher_home",
      "addClass": "left-nav-dashboard",
      "subItems": [
        {
          "display_name": "Dashboard",
          "name_slug": "teacher_home",
          "link": `/teacher${commonLinkPrefix}/dashboard`
        }
      ],
      "displayGradeDropdown": false
    },
    "worksheet": {
      "svg_icon": TeacherIcons.WorksheetIcon,
      "display_name": "Worksheets",
      "name_slug": "worksheet",
      "newFeature": false,
      "addClass": "left-nav-dashboard",
      "hideExpandMenu": true,
      "subItems": [
        {
          "display_name": "Math worksheets",
          "name_slug": "math_worksheet",
          "link": `${commonLinkPrefix}/math_worksheet`
        },
        {
          "display_name": "ELA worksheets",
          "name_slug": "ela_worksheet",
          "link": `${commonLinkPrefix}/ela_worksheet`
        }
      ],
      "displayGradeDropdown": false
    },
    "assign": {
      "svg_icon": TeacherIcons.AssignIcon,
      "display_name": "Assign curriculum",
      "name_slug": "assign",

      "subItems": [
        {
          "display_name": "New assignment",
          "name_slug": "curriculum",
          "link": `${commonLinkPrefix}/curriculum`
        },
        {
          "display_name": "Track assignment",
          "name_slug": "class_assignment",
          "link": `${commonLinkPrefix}/class_assignment`
        },
      ],
      "displayGradeDropdown": true
    },
    "math_fact": {
      "svg_icon": TeacherIcons.AssignIcon,
      "display_name": "Assign facts",
      "name_slug": "math_fact",
      "newFeature": false,

      "subItems": [
        {
          "display_name": "New fact practice",
          "name_slug": "assign_practices",
          "link": `${commonLinkPrefix}/assign_practices`,
          "show_pulse_animation": true
        },
        {
          "display_name": "Track fact practice",
          "name_slug": "track_practices",
          "link": `${commonLinkPrefix}/track_practices`,
          "show_pulse_animation": true
        },
      ],
      "displayGradeDropdown": false
    },
    "assess": {
      "svg_icon": TeacherIcons.AssessIcon,
      "display_name": "Assess",
      "name_slug": "assess",


      "subItems" :[
        {
          "display_name": "New assessment",
          "name_slug": "class_assessment",
          "link": `${commonLinkPrefix}/class_assessment`
        },
        {
          "display_name": "Track assessment",
          "name_slug": "track_assessment",
          "link": `${commonLinkPrefix}/track_assessment`
        }],
        "displayGradeDropdown": true
    },
    "review": {
      "svg_icon": TeacherIcons.ReviewIcon,
      "display_name": "Review",
      "name_slug": "review",


      "subItems" : [
        {
          "display_name": "Daily activity",
          "name_slug": "daily-activity",
          "link": `${commonLinkPrefix}/daily-activity`,
          "displayGradeDropdown": false
        },
        {
          "display_name": "Class progress",
          "name_slug": "performance-overview",
          "link": `${commonLinkPrefix}/performance-overview`
        },
        {
          "display_name": "Student progress",
          "name_slug": "performance-by-student",
          "link": `${commonLinkPrefix}/performance-by-student`
        }
      ],
      "displayGradeDropdown": true
    },
    "manage_class": {
      "svg_icon": TeacherIcons.ManageIcon,
      "display_name": "Manage Class",
      "name_slug": "manage_class",


      "subItems" :[
        {
          "display_name": "Student roster",
          "name_slug": "roster",
          "link": `${commonLinkPrefix}/roster`
        },
        {
          "display_name": "Class settings",
          "name_slug": "classroom_setting",
          "link": `${commonLinkPrefix}/classroom_setting`
        }],
        "displayGradeDropdown": false
    },
  }

  return navKey ? navigationMap[navKey] : navigationMap;
};

const navigationMenuMap = {
  "daily-activity" : {
    pageName: "daily-activity",
    parentName: "review",
    "display_name": "Daily activity",
  },
  "teacher_home": {
    pageName: "teacher_home",
    parentName: "teacher_home",
    "display_name": "Dashboard",
  },
  "dashboard": {
    pageName: "teacher_home",
    parentName: "teacher_home",
    "display_name": "Dashboard",
  },
  "home": {
    pageName: "teacher_home",
    parentName: "teacher_home",
    "display_name": "Dashboard",
  },
  "left-nav-dashboard": {
    pageName: "teacher_home",
    parentName: "teacher_home",
    "display_name": "Dashboard",
  },
  "performance-overview": {
    pageName: "performance-overview",
    parentName: "review",
    "display_name": "Class progress",

  },
  "performance-by-student": {
    pageName: "performance-by-student",
    parentName: "review",
    "display_name": "Student progress",
  },
  "curriculum": {
    pageName: "curriculum",
    parentName: "assign",
    "display_name": "New assignment",
  },
  "class_assignment": {
    pageName: "class_assignment",
    parentName: "assign",
    "display_name": "Track assignment",
  },
  "assign_practices": {
    pageName: "assign_practices",
    parentName: "math_fact",
    "display_name": "New Fact practice",
  },
  "track_practices": {
    pageName: "track_practices",
    parentName: "math_fact",
    "display_name": "Track fluency",
  },
  "math_worksheet": {
    pageName: "math_worksheet",
    parentName: "worksheet",
    "display_name": "Worksheets",
  },
  "ela_worksheet": {
    pageName: "ela_worksheet",
    parentName: "worksheet",
    "display_name": "Worksheets",
  },
  "class_assessment": {
    pageName: "class_assessment",
    parentName: "assess",
    "display_name": "New assessment",
  },
  "track_assessment": {
    pageName: "track_assessment",
    parentName: "assess",
    "display_name": "Track assessment",
  },
  "roster": {
    pageName: "roster",
    parentName: "manage_class",
    "display_name": "Student roster",
  },
  "classroom_setting": {
    pageName: "classroom_setting",
    parentName: "manage_class",
    "display_name": "Class settings",
  },
  "class_management_home_access": {
    pageName: "class_management_home_access",
    parentName: "klass_home",
    "display_name": "Home Access",
  },
  "student_center": {
    pageName: "student_center",
    parentName: "klass_home",
    "display_name": "Student Experience"
  },
  "transfer_class": {
    pageName: "transfer_class",
    parentName: "klass_home",
    "display_name": "Transfer Class",
  },
};

const languages = {
  en: "English",
  es: 'Spanish',
  'en-GB': 'English(UK)',
  'en-AU': 'English(AU)'
};

const subjectPreferenceCdMapping = {
  'math': 0,
  'ela': 1,
  'mela': 2
};

const imagePaths = {
  splashLearnLogo: assetPath(HeaderLogoImage),
  loaderGif: assetPath(ComponentLoaderGif),
  oozloo: assetPath(OolzooImage),
  noAssignmentLandingImage: assetPath(NoAssignmentImage),
  ela: {
    comingSoonCg: {
      sight: assetPath(SightWordsImage),
      leveled: assetPath(LeveledImage),
      decodable: assetPath(DecodeableImage),
    },
    swLoPatterns: {
      pink: assetPath(PinkImage),
      darkgreen: assetPath(DarkgreenImage),
      blue: assetPath(BlueImage),
      darkPink: assetPath(DarkPink),
      yellow: assetPath(YellowImage),
      green: assetPath(GreenImage),
    }
  },
  avatars: {
    "avatars/child-1-thumb.png" : assetPath(Child1Image),
    "avatars/child-2-thumb.png" : assetPath(Child2Image),
    "avatars/child-3-thumb.png" : assetPath(Child3Image),
    "avatars/child-4-thumb.png" : assetPath(Child4Image),
    "avatars/child-5-thumb.png" : assetPath(Child5Image),
    "avatars/child-6-thumb.png" : assetPath(Child6Image),
    "avatars/child-1.png" : assetPath(Child1Avatar),
    "avatars/child-2.png" : assetPath(Child2Avatar),
    "avatars/child-3.png" : assetPath(Child3Avatar),
    "avatars/child-4.png" : assetPath(Child4Avatar),
    "avatars/child-5.png" : assetPath(Child5Avatar),
    "avatars/child-6.png" : assetPath(Child6Avatar),
  },
  starCount: {
    0 : assetPath(Star0Image),
    1 : assetPath(Star1Image),
    2 : assetPath(Star2Image),
    3 : assetPath(Star3Image),
  },
  emptyScreen: {
    searchWorksheet: assetPath(NoSearchImage),
    bookmarkWorksheet: assetPath(NoBookmarkImage),
  }
};
const sightWordsImagesOrder = [
   imagePaths.ela.swLoPatterns.pink,
   imagePaths.ela.swLoPatterns.darkgreen,
   imagePaths.ela.swLoPatterns.blue,
   imagePaths.ela.swLoPatterns.darkPink,
   imagePaths.ela.swLoPatterns.yellow,
   imagePaths.ela.swLoPatterns.green
]

const headerLinks = {
  rootLink: '/',
  dashboardLink: (klassSlug, gradeCode) => `/teacher${generateCommonLinkPrefix(klassSlug, gradeCode)}/dashboard`,
  gettingStartedGuideLink: (gradeCode) => getGuideLinkFromCode(gradeCode),
  howToVideosLink: "https://support.splashlearn.com/hc/en-us/articles/360047214853-Virtual-Tour-of-SplashLearn-for-Teachers",
  faqLink: "https://support.splashlearn.com/hc/en-us/categories/12271297576722-I-am-a-Teacher",
  profileLink: "/profile",
  studentCenterLink: (teacherId) =>  `/teacher/${teacherId}/student_center`,
  manageClassesLink: "/classes/manage?src=ml",
  referSplashLearnLink: "/teacher/refer_teacher",
  signOutLink: "/signout",
  forgotPasswordLink: "/forgot_password"
};

const headerContactDetails = {
  contactNumber: "1-855-979-8948",
  email: "support@splashlearn.com",
  support_link: "https://splashlearn.zendesk.com/hc/en-us/requests/new"
};



const LeftNavSubjectMenuItems = {
  math: ["assign", "math_fact", "assess", "review"],
  ela: ["assign", "review"]
};

/**
 * This function can used to define defferent action at UI when there is error code in APi response
 * @param {Object} apiResponse - Error Information
 * @param {String} responseBody - If there is any response from backend for the error
 */
 const handleResponseError = (apiResponse, responseBody, noReloadOnError, noFlashMessageOnError = false) => {
  let errorMessage = '';
  let reloadFlag = false;

  switch(apiResponse.status) {
    case 401:
      reloadFlag = true;
      break;
    case 403: // will handle
    case 402: // will handle
    case 500: // will handle
    default:
      errorMessage = CommonMessages.commonErrorMessage;
  }

  if(responseBody) {
    const message = responseBody.error || responseBody.message;
    if(typeof message === 'string') {
      errorMessage = message;
    }
  }

  if(errorMessage && !noFlashMessageOnError) {
    showFlashMessage(errorMessage, 'error');
  }

  if(reloadFlag && !noReloadOnError) {
    window.location.reload(true);
  }
}


const apiCaller = async function(config){
  var dummyData = config.dummyData || {};
  var url = config.path;
  var methodType = config.method || 'get';
  var params = config.params || {};
  var noReloadOnError = config.noReloadOnError || false;
  var noFlashMessageOnError = config.noFlashMessageOnError || false;

  const EXT_SERVICE_MODE = 'cors';
  const EXT_SERVICE_CREDENTIALS = 'include';

  let extServiceFlag =  config.extServiceFlag || false;


  var csrfToken = document.querySelector('meta[name=csrf-token]').content;

  var successCallback = config.callback;
  var errorCallback = config.errorCallback || function(){};

  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      return false
    }
  }

  var requestConfig = {
    method: methodType,
    credentials: "same-origin",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-CSRF-Token': csrfToken,
      "X-Requested-With": "XMLHttpRequest",
    }
  }

  //Adding user_id to headers for external api calls since on local env we dont have api-gateway nand auth infra
  if(APP_MODE == "development" && extServiceFlag) {
    const state = Store.getState();
    const userId = (state.teacherProfile && state.teacherProfile.teacher && state.teacherProfile.teacher.id) ? state.teacherProfile.teacher.id : null;
    requestConfig.headers["user_id"] = userId;
    extServiceFlag = false;
  }

  if (extServiceFlag) {
    requestConfig['mode'] = EXT_SERVICE_MODE;
    requestConfig['credentials'] = EXT_SERVICE_CREDENTIALS;
  }
  if(methodType != 'get' && !!params){
    requestConfig.body = JSON.stringify(params);
  }

  if (!config.dontShowAjaxSpinner) {
    GlobalSpinner.show();
  }
  if(Object.keys(dummyData).length > 0 && dummyData.constructor === Object){
    successCallback && successCallback(dummyData);
  } else {
    let response = {};
    let parsedResponse = {};
    try {
      response = await fetch(url, requestConfig)
      parsedResponse = await response.json();
    } catch(e) {
      // throwApiResposeReadingError(e, response);
    }
    let isRequestSuccessful = checkStatus(response);
    if(isRequestSuccessful){
      if (!config.dontShowAjaxSpinner) {
        GlobalSpinner.hide();
      }
      successCallback && successCallback(parsedResponse)
    } else {
      if (!config.dontShowAjaxSpinner) {
        GlobalSpinner.hide();
      }
      handleResponseError(response, parsedResponse, noReloadOnError, noFlashMessageOnError);
      errorCallback && errorCallback(parsedResponse);
      // throwApiError(response);
    }
  }
}


const newApiCaller = async function (config) {
  var dummyData = config.dummyData || {};
  var url = config.path;
  var methodType = config.method || 'get';
  var params = config.params || {};

  const EXT_SERVICE_MODE = 'cors';
  const EXT_SERVICE_CREDENTIALS = 'include';

  let extServiceFlag =  config.extServiceFlag || false;


  var csrfToken = document.querySelector('meta[name=csrf-token]').content;

  var successCallback = config.callback;
  var errorCallback = config.errorCallback || function(){};

  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return true
    } else {
      return false
    }
  }

  var requestConfig = {
    method: methodType,
    credentials: "same-origin",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      'X-CSRF-Token': csrfToken,
      "X-Requested-With": "XMLHttpRequest",
    }
  }

  //Adding user_id to headers for external api calls since on local env we dont have api-gateway nand auth infra
  if(APP_MODE == "development" && extServiceFlag) {
    const state = Store.getState();
    const userId = (state.teacherProfile && state.teacherProfile.teacher && state.teacherProfile.teacher.id) ? state.teacherProfile.teacher.id : null;
    requestConfig.headers["user_id"] = userId;
    extServiceFlag = false;
  }

  if (extServiceFlag) {
    requestConfig['mode'] = EXT_SERVICE_MODE;
    requestConfig['credentials'] = EXT_SERVICE_CREDENTIALS;
  }
  if(methodType != 'get' && !!params){
    requestConfig.body = JSON.stringify(params);
  }

  if (!config.dontShowAjaxSpinner) {
    GlobalSpinner.show();
  }
  if (Object.keys(dummyData).length > 0 && dummyData.constructor === Object) {
    successCallback && successCallback(dummyData);
  } else {
    let response = {};
    let parsedResponse = {};
    try {
      response = await fetch(url, requestConfig);
      parsedResponse = await response.json();
    } catch (e) {
      // throwApiResposeReadingError(e, response);
    }
    let isRequestSuccessful = checkStatus(response);
    if (isRequestSuccessful) {
      if (!config.dontShowAjaxSpinner) {
        GlobalSpinner.hide();
      }
      successCallback && successCallback(parsedResponse);
      return Promise.resolve(parsedResponse);
    } else {
      if (!config.dontShowAjaxSpinner) {
        GlobalSpinner.hide();
      }
      handleResponseError(response, parsedResponse);
      errorCallback && errorCallback(parsedResponse);
      return Promise.reject(parsedResponse);
    }
  }
};

const getFormattedGradeCode = (code) => {
  switch (String(code).toUpperCase()) {
    case "K":
      return "kindergarten";
    case "1":
      return "first-grade";
    case "2":
      return "second-grade";
    case "3":
      return "third-grade";
    case "4":
      return "fourth-grade";
    case "5":
      return "fifth-grade";
  }
};

const getGradeCodeFromFormattedGradeCode = (code) => {
  switch (code) {
    case "kindergarten":
      return "K";
    case "first-grade":
      return "1";
    case "second-grade":
      return "2";
    case "third-grade":
      return "3";
    case "fourth-grade":
      return "4";
    case "fifth-grade":
      return "5";
  }
};

const getGuideLinkFromCode = (code) => {
  if(code == "K" || code < "3"){
    return "https://cdn.splashmath.com/common/Getting_started_guide_Kintergarten_to_Grade_2.pdf";
  }
  return "https://cdn.splashmath.com/common/Getting_started_guide_Grade_3-5.pdf";
}

const copyInstructions = ({classReferralLink, classCode}) => {
  let text = `Hello Parent,\r\nWe are using SplashLearn in class this year. To enable your child to complete assigned tasks and access books at home, please sign up on SplashLearn for free \r\n\nSteps to set up home access\r\n 1. Go to ${classReferralLink}\r\n 2. Sign up for free and verify your email.\r\n 3. Enter class code: ${classCode}\r\n 4. Select your child’s profile to complete setup.\r\n\n Additionally, you can save 37% on subscription with my link.`
  return text
}

const copyLiveClassInstructions = ({classCode, classReferralLink}) => {
  let text = `Hello Parent,\r\n I am sharing a new after-school live class being conducted by SplashLearn. I believe students can benefit from this session.\r\n 1. Click on the link to access the live class that I've recommended ${classReferralLink} \r\n 2. Sign up to get free access to this live class at home.\r\n 3. Enter your class code: ${classCode}, and Select your child’s profile to complete setup.\r\n Additionally, if you choose to get unlimited access to all of SplashLearn for after-school usage, you can save 37% on subscription with my link.`
  return text
}

const copyLiveClassInstructionsV1 = ({passwordText, classReferralLink}) => {
  let text = `Hello Parent,\r\n I am sharing a new after-school live class being conducted by SplashLearn. I believe students can benefit from this session.\r\n 1. Click on the link to access the live class that I've recommended ${classReferralLink} \r\n 2. Select your child’s profile and enter password\r\n Password: ${passwordText}\r\n 3. Sign up for home access to complete setup\r\n Additionally, you can save 37% on subscription with my link.`
  return text
}

const copySummerInstructions = ({classReferralLink, classCode}) => {
  let text = `Hello Parent,\r\nMy students love using SplashLearn in class. I'm sure your child would love to continue using it through the summer break as well. To let your child continue learning through the summer, please sign up on SplashLearn for free. \r\nSteps to set up home access\r\n 1. Go to ${classReferralLink}\r\n 2. Sign up for free and verify your email.\r\n 3. Enter class code: ${classCode}\r\n 4. Select your child’s profile to complete setup.`
  return text
}

const copySpringboardInstructions = ({classReferralLink, currentSchoolYear}) => {
  let text = `Hello Parent,\r\nYour child’s efforts have paid off! Your child’s class has won certificate(s) during Springboard ${currentSchoolYear}. \r\nClick ${classReferralLink} to securely download the certificate. \r\n(We’ll need to validate your email address to prevent any unauthorized access.) \r\nNow, let’s sprint forward with increased enthusiasm! There are new certificates across a wide range of categories up for grabs every week. Keep them playing to build that certificate tower for bragging rights!Remember, playing at home increases their chances of winning as that adds additional points to the overall class points. \r\nAdditionally, you can save 37% on subscription with my link.`
  return text
}

/**
 * List of error messages to be shown across all components
 */
const errorsMap = {
  "emptyFormField": "Can't be blank",
  "studentNameTooShort": "is too short (minimum is 2 characters)",
  "studentNameTooLong": "is too long (maximum is 255 characters)",
  "studentPasswordTooShort": "is too short (minimum is 6 characters)",
  "specialCharactersNotAllowedInName": "can contain only letters, numbers, apostrophes(') and dots(.)",
  "invalidEmail": "Email is invalid"
}

/**
 * validateField will fetch field value and validate it depending on field type
 * @param {String} type - type of field to validate
 * @param {HTMLElement} elToValidate - The element of field to validate
 *
 */
const validateFormField = (type, elToValidate) => {
  let isValid = true;
  const nameReg = /^[ a-zA-Z0-9.']*$/;
  const silblingEl = elToValidate.nextElementSibling;

  if(type == 'email'){
    const state = Store.getState();
    const email = elToValidate?.value.trim()
    const isDomainBlocked = isEmailDomainBlocked(email)
    const hasValidEmailReg = hasValidEmailRegex(email)
    const hasValidEmailLength = checkValidEmailLength(email)
    const selfTeacherEmail = isSelfTeacherEmail(email, state?.teacherProfile?.teacher?.email)
    const isValidEmail = hasValidEmailReg && !isDomainBlocked && hasValidEmailLength && !selfTeacherEmail
    //Check if email is empty
    if(elToValidate.value == ''){
      silblingEl.innerHTML = errorsMap["emptyFormField"];
      isValid = false;
    }
    //Check if email is valid
    else if(!isValidEmail){
      silblingEl.innerHTML = errorsMap["invalidEmail"];
      isValid = false;
    }
  }

  else if(type == 'password'){
    //Check if password is empty
    if(elToValidate.value == ''){
      silblingEl.innerHTML = errorsMap["emptyFormField"];
      isValid = false;
    }
    else if(elToValidate.value.length < 6){
      silblingEl.innerHTML = errorsMap["studentPasswordTooShort"];
      isValid = false;
    }
  }

  else if(type == "firstname"){
    //Check if firstname is empty
    if(elToValidate.value == ''){
      silblingEl.innerHTML = errorsMap["emptyFormField"];
      isValid = false;
    }
    //Check if firstname contains any special characters
    else if(!nameReg.test(elToValidate.value)){
      silblingEl.innerHTML = errorsMap["specialCharactersNotAllowedInName"];
      isValid = false;
    }
    //Check min and max length
    else if(elToValidate.value.length < 2){
      silblingEl.innerHTML = errorsMap["studentNameTooShort"]
      isValid = false;
    }
    else if(elToValidate.value.length > 255){
      silblingEl.innerHTML = errorsMap["studentNameTooLong"]
      isValid = false;
    }
  }

  else if(type == "lastname"){
    //Check if lastname contains any special characters
    if(!nameReg.test(elToValidate.value)){
      silblingEl.innerHTML = errorsMap["specialCharactersNotAllowedInName"];
      isValid = false;
    }
  }

  return isValid;
};

const blockedDomains = [
  "xyz.com",
  "iubridge.com",
  "linshiyou.com",
  "163.com",
  "youxiang.dev",
  "coffeetimer24.com",
  "qq.com",
  "etondy.com",
];

const blockedDomainCategories = ["us", "net", "org"];

const checkValidEmailLength = (email) => {
  const emailPrefix = email?.split('@')[0];
  if(email?.length <= EmailConstants.MAX_LENGTH && emailPrefix?.length <= EmailConstants.EMAIL_PREFIX_LENGTH){
    return true
  }

  return false
}

const isEmailDomainBlocked = (email) => {
  const domain = email?.split('@')[1];
  return blockedDomains.includes(domain) || false;
}

const isDomainCategoriesBlocked = (email) => {
  const domain = email?.split('@')[1];
  const domainCategory = domain?.split('.')[1]
  return blockedDomainCategories.includes(domainCategory) || false;
}

const isSelfTeacherEmail = (email, teacherEmail) => {
  return email === teacherEmail
}

const hasValidEmailRegex = (email) => {
  return EmailConstants.REGEX?.test(email)
}

function getSwBgImagePath(index) {
  let rem = index % 6;
  // rem is used to determine which image will be returned corresponding to index received.
  return sightWordsImagesOrder[rem];
}
function documentCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  textArea.style.width = '2em';
  textArea.style.height = '2em';

  textArea.style.padding = 0;

  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  textArea.style.background = 'transparent';


  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}


const copyTextToClipboard = (textToCopy, callback) => {
  if (!navigator.clipboard) {
    documentCopyTextToClipboard(textToCopy);
  }
  else{
    // navigator.clipboard is an async operation. Due to some synchronous operation it was throwing error in safari due to security. Moving it to deferTask helps it to execute after synchronous operation.
    const taskToDefer = () => {
      try {
        navigator.clipboard.writeText(textToCopy).catch((e) => {
          documentCopyTextToClipboard(textToCopy);
        })
      } catch (e) {
        documentCopyTextToClipboard(textToCopy);
      }
    }
    deferTask({taskToDefer: taskToDefer, timeOut: 0})
  }
  if(callback){
    callback(true);
    const taskToDefer = () => {
      callback(false)
    }
    deferTask({taskToDefer: taskToDefer, timeOut: 4000})
  }
}

const isUSTeacher = (countryCode) => {
  return (countryCode === "US")
}

const isUsOrCanadaTeacher = (countryCode) => {
  return ["US", "CA"].includes(countryCode);
}

export const isEligibleForMilestone = (subjectCode) => {
  const state = Store.getState();
  const { showMilestoneUiChanges = false } = state?.appFlags || {};
  const { teacher = {} } = state?.teacherProfile || {};

  if(!isUsOrCanadaTeacher(teacher?.country_code)) {
    return showMilestoneUiChanges;
  }

  if(subjectCode === "ela") {
    return showMilestoneUiChanges;
  }

  return true;
}

// generic function to copy an element by id and replace br tags by new line
const copyElementById = (elementId) => {
  var $temp = $("<textarea>");
  var brRegex = /<br\s*[\/]?>/gi;
  $("body").append($temp);
  $temp.val(document.getElementById(elementId).innerHTML.replace(brRegex, "\r\n"))
  $temp.focus({preventScroll: true})
  $temp.select()
  document.execCommand("copy");
  $temp.remove();
  $(".copied-text").css({"display": "block"});
}

// find class password as text
const copyClassPassword = (classPasswordSettings) => {
  if (classPasswordSettings && classPasswordSettings.common_password_enabled) {
    if (classPasswordSettings.common_picture_password_enabled) {
      let picturePasswordName;
      let picturePasswordTemp = classPasswordSettings.common_picture_password.split("/");
      picturePasswordName = picturePasswordTemp[picturePasswordTemp.length - 2]
      return picturePasswordName
    } else {
      return classPasswordSettings.common_plain_text_password
    }
  }
}

// copy assignment link message
const copyAssignmentLinkMessage = ({classPasswordSettings, assignment_share_link, classCode}) => {
  let text = ""
  if (classPasswordSettings && classPasswordSettings.common_password_enabled) {
      text = `Hello there,\r\n1. Click on the link to access the assignment ${assignment_share_link}\r\n2. Enter your class code and password.\r\n Class Code: ${classCode}\r\n Password: ${copyClassPassword(classPasswordSettings)}\r\n3. Sign up to get free access to the teacher-assigned activities at home.\r\nAdditionally, you can save 37% on subscription with my link above.`
    }
  else {
    text = `Hello there,\r\n1. Click on the link to access the assignment ${assignment_share_link}\r\n2. Enter your class code and password.\r\n Class Code: ${classCode}\r\n Password: If not received yet, please contact your class teacher\r\n3. Sign up to get free access to the teacher-assigned activities at home.\r\nAdditionally, you can save 37% on subscription with my link above.`
  }
  return text
}

// copy math fact link message
const copyMathFactLinkMessage = ({classPasswordSettings, assignment_share_link, classCode}) => {
  let text = ""
  if (classPasswordSettings && classPasswordSettings.common_password_enabled) {
      text = `Hello there,\r\n1. Click on the link to access the math fact ${assignment_share_link}\r\n2. Enter your class code and password.\r\n Class Code: ${classCode}\r\n Password: ${copyClassPassword(classPasswordSettings)}\r\n3. Sign up to get free access to the teacher-assigned activities at home.\r\nAdditionally, you can save 37% on subscription with my link above.`
    }
  else {
    text = `Hello there,\r\n1. Click on the link to access the math fact ${assignment_share_link}\r\n2. Enter your class code and password.\r\n Class Code: ${classCode}\r\n Password: If not received yet, please contact your class teacher\r\n3. Sign up to get free access to the teacher-assigned activities at home.\r\nAdditionally, you can save 37% on subscription with my link above.`
  }
  return text
}

const capitalizeFirstLetter = (string = "") => {
  let res = ``;
  try {
   res = string?.charAt(0).toUpperCase() + string?.slice(1);
  } catch (error) {
    console.error("Error in capitalizeFirstLetter(). Error: ", error);
  }
  return res;
}

const decodeCharFromHTMLEntity = (htmlChar) => {
  if(htmlChar && htmlChar?.length) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlChar;
    const decodedChar = tempElement.textContent;
    tempElement.remove();
    return decodedChar;
  }

  return htmlChar;
}

const formatStudentNameFromFullName = (fullname = "") => {
  try{
    const [firstname, lastname] = fullname?.split(" ");
    return formatStudentName(firstname??"", lastname??"");
  } catch (error) {
    console.error("Error in formatStudentNameFromFullName(). Error: ", error);
  }
}

const formatStudentName = (firstname = "", lastname = "") => {
  return decodeCharFromHTMLEntity(`${firstname ? formatFirstName(firstname) : ''}${(firstname && lastname) ? ' ' : ''}${lastname ? formatLastName(lastname) : ''}`);
};

const formatTeacherName = (firstname = "", lastname = "") => {
  return decodeCharFromHTMLEntity(`${firstname ? capitalizeFirstLetter(firstname) : ''}${(firstname && lastname) ? ' ' : ''}${lastname ? capitalizeFirstLetter(lastname) : ''}`);
}

const formatFirstName = (firstname = "") => {
  return capitalizeFirstLetter(decodeCharFromHTMLEntity(firstname));
}

const formatLastName = (lastname = "") => {
  if(lastname.length >= 1) {
    return decodeCharFromHTMLEntity(lastname.charAt(0).toUpperCase());
  }
}

const formatFullLastName = (lastname = "") => {
  if(lastname.length >= 1) {
    return decodeCharFromHTMLEntity(lastname.capitalize());
  }
}

const formatNumber = (number) => {
  return number.toLocaleString()
}

const currentAvailableProgressQuantifiers = ['SATISFACTORY', 'CAN_DO_BETTER', 'STRUGGLING', 'MASTERED', 'INCOMPLETE', 'YET_TO_START'];

const iPadWidth = 1080;

const classNameMaxLength = 14;
const classNameMaxLengthClassSettings = 32;
const classNameMinLength = 3;
const classNameMaxLengthForAnyClass = 50;
const elaAnnouncementEligibiltyDate = '2021-09-07';

const gradesEnabledForELA = ["K", "1", "2", "k"];

//Mapping for status of task
const dropdownTypeStatusCd = {
  'ongoing': '0',
  'finished': '1',
  'marked_as_finish': '2'
}

//UI notification elements coming from backend
const uiNotificationElementTypes = {
  banner: "banner",
  popUp: "popup"
}

const fromPage = {
  curriculum: "Curriculum",
}

const curriculumViewTypes = {
  milestone: "Milestone",
  standard: "Standard",
}

//Mapping for telephoneCountryCodes
const telephoneCountryCodes = {
  US: '+1'
}

const getTagFromDayInt = (dayInt) => {
  switch(dayInt){
    case 1: return "M";
    case 2: return "T";
    case 3: return "W";
    case 4: return "T";
    case 5: return "F";
    case 6: return "S";
    case 7: return "S";
  }
};

const trimData = (studentNameArray = []) => {
  return studentNameArray.map((studentFullName) => {
      studentFullName =  studentFullName?.trim();
      studentFullName =  studentFullName?.split("\n")?.join("");
      studentFullName =  studentFullName?.split("\t")?.join("");
      studentFullName =  studentFullName?.split("\r")?.join("");
      return studentFullName;
  })
}



const sendEventUsingAvo = (props = {}, explicitInitParams = null) => {
  const state = Store.getState();

  const klasses = state.teacherProfile?.klasses;
  const klassId = explicitInitParams ? explicitInitParams?.klass?.id : state?.currentKlass?.klass?.id;
  const klassInfo = klasses?.find((klassInstance) => klassInstance.id === klassId);
  const isCoTeacher = klassInfo?.co_teaching_klass;

  const initParams = explicitInitParams ? explicitInitParams : {
    klassGrade: state.currentKlass?.klass?.gradeCode,
    currentGrade: state.currentKlass?.current_course?.grade_code,
    subjectCode: state.currentKlass?.current_course?.subject_code,
    klass: state.currentKlass?.klass,
  };
  initParams.teacherType = isCoTeacher ? 'Co-Teacher' : 'Primary';

  dispatchAvoEvent(props, initParams || {});
} ;

const findGradeUsingSeedData = (gradeId) => {
  const state = Store.getState();
  return state?.gradeSeedData?.grades?.find((g) => g.id === gradeId)?.['code']?.toLowerCase()
}

const getInitParamsFromKlass = (klass) => {
  let currentKlassDetailsForEvents = {};
  try {
      currentKlassDetailsForEvents = JSON.parse(localStorage?.getItem('CURRENT_KLASS_DETAILS') || JSON.stringify({}));
  } catch (e) {
      sendErrorToSentry(e, {currentKlassDetailsForEvents: localStorage?.getItem('CURRENT_KLASS_DETAILS')});
  }
  const toReturn = {
      klassGrade: findGradeUsingSeedData(klass?.grade_id || klass?.gradeId),
      currentGrade: currentKlassDetailsForEvents?.currentGrade,
      subjectCode: currentKlassDetailsForEvents?.subjectCode,
      klass: klass,   
  }
  return toReturn;
}

const playableImage = (code, curriculumCode) => {
  const folderPath = curriculumCode && ['uk', 'aus'].includes(curriculumCode) ? `curriculum_uploads/images/${curriculumCode}/playables` : "curriculum_uploads/images/playables";
  const imageName = code?.replace(/[\W_]+/g, "_") + '.png';
  const cdnHost = window.SCHOOL_UI_ASSET_HOST;
  return `${cdnHost}/${folderPath}/${imageName}`
}

export {
  getFormattedGradeCode,
  getGradeCodeFromFormattedGradeCode,
  LeftNavSubjectMenuItems,
  getNavigationMapping as GetNavigationMapping,
  apiCaller as ApiCaller,
  navigationMenuMap as NavigationMenuMap,
  commonLinkPrefix as CommonLinkPrefix,
  fetchRouteNameFromPath as FetchRouteNameFromPath,
  generateCommonLinkPrefix as GenerateCommonLinkPrefix,
  paths as Paths,
  abtestMapping as AbTestMapping,
  languages as Languages,
  imagePaths as ImagePaths,
  headerLinks as HeaderLinks,
  headerContactDetails as HeaderContactDetails,
  elaAnnouncementEligibiltyDate as ElaAnnouncementEligibiltyDate,
  subjectPreferenceCdMapping as SubjectPreferenceCdMapping,
  validateFormField as ValidateFormField,
  classNameMaxLength as ClassNameMaxLength,
  classNameMinLength as ClassNameMinLength,
  classNameMaxLengthForAnyClass as ClassNameMaxLengthForAnyClass,
  gradesEnabledForELA as GradesEnabledForELA,
  fetchClassSlugFromPath as FetchClassSlugFromPath,
  classNameMaxLengthClassSettings as ClassNameMaxLengthClassSettings,
  fetchGradeCodeFromPath as FetchGradeCodeFromPath,
  getSwBgImagePath as GetSwBgImagePath,
  iPadWidth  as IpadWidth,
  currentAvailableProgressQuantifiers as CurrentAvailableProgressQuantifiers,
  copyInstructions as CopyInstructions,
  copyTextToClipboard as CopyTextToClipboard,
  isUSTeacher as IsUSTeacher,
  copyElementById as CopyElementById,
  copyClassPassword as CopyClassPassword,
  dropdownTypeStatusCd as DropdownTypeStatusCd,
  uiNotificationElementTypes as UiNotificationElementTypes,
  telephoneCountryCodes as TelephoneCountryCodes,
  copyAssignmentLinkMessage as CopyAssignmentLinkMessage,
  copyMathFactLinkMessage as CopyMathFactLinkMessage,
  fromPage as FromPage,
  curriculumViewTypes as CurriculumViewTypes,
  capitalizeFirstLetter as CapitalizeFirstLetter,
  formatNumber as FormatNumber,
  getTagFromDayInt as GetTagFromDayInt,
  sendEventUsingAvo,
  newApiCaller as NewApiCaller,
  formatFirstName as FormatFirstName,
  formatLastName as FormatLastName,
  formatStudentName as FormatStudentName,
  formatTeacherName as FormatTeacherName,
  formatStudentNameFromFullName as FormatStudentNameFromFullName,
  formatFullLastName as FormatFullLastName,
  trimData as TrimData,
  copySpringboardInstructions as CopySpringboardInstructions,
  copySummerInstructions as CopySummerInstructions,
  blockedDomains as BlockedDomains,
  blockedDomainCategories as BlockedDomainCategories,
  getInitParamsFromKlass as GetInitParamsFromKlass,
  isUsOrCanadaTeacher as IsUsOrCanadaTeacher,
  copyLiveClassInstructions as CopyLiveClassInstructions,
  checkValidEmailLength as CheckValidEmailLength,
  isSelfTeacherEmail as IsSelfTeacherEmail,
  isEmailDomainBlocked as IsEmailDomainBlocked,
  isDomainCategoriesBlocked as IsDomainCategoriesBlocked,
  hasValidEmailRegex as HasValidEmailRegex,
  playableImage as PlayableImage,
  decodeCharFromHTMLEntity as DecodeCharFromHTMLEntity,
  copyLiveClassInstructionsV1 as CopyLiveClassInstructionsV1
};
