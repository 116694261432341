export const TEACHER_PATH_PREFIX = "/web_api/teachers/v1";
export const SCHOOL_BE_PATH_PREFIX = "/school-service/api/v1";
export const SCHOOL_BE_PATH_PREFIX_V2 = "/school-service/api/v2";
export const COMMON_PATH_PREFIX = "/web_api/common/v1";
export const CURRICULUM_PATH_PREFIX = "/web_api/curriculum_service/v1";
export const REPORTING_PATH_PREFIX = "/web_api/reporting_service/v1";
export const STUDENTS_PATH_PREFIX = "/web/api/students/v1";
export const USERS_PATH_PREFIX = "/web_api/v1";
export const SUPPORT_EMAIL_SPLASHLEARN = 'help@splashlearn.com';
export const PLAYABLE_SERVICE_URL = '/playable-service';
export const AB_TEST_SERVICE_PATH_PREFIX = '/abtest-service/api/v1';
export const EXPLORATORY_PATH_PREFIX = "/exploratory-games-service/api/v1/teachers";
export const ROOT_URL = "https://www.splashlearn.com/"
export const CMS_PATH_PREFIX = "cms-service/public/api/v1"
export const CURRICULUM_PUBLIC_PATH_PREFIX = "/curriculum_service/public/api/v1";
export const GAMES_SERVICE_PATH_PREFIX = "/games/v1";

//Assignment with these codes are shown as stars (instead of text) on assignment report page
export const bookTypeContentGroups = ['db', 'lr']

export const booksReadingModeMapping = {
  'read_to_student': 'read to student by system',
  'read_by_student': 'read by student'
}

//Mapping for activities IDs of daily activity report
export const detailed_activity_source_mapping = {
  "0" : "Independent Practice: Curriculum",
  "1" : "Independent Practice: Curriculum",
  "2" : "Independent Practice: Curriculum",
  "3" : "Independent Practice: Math Games",
  "5" : "Assignment",
  "6" : "Learning Plan",
  "901" : "Assessment",
  "902" : "Independent Practice: Math Facts"
}

//Mapping for student legend levels
export const studentLegendLevels = [
  {
    "name": "Mastered",
    "class_name": "mastered",
    "desc": "Completed all playables with accuracy > 80%"
  },
  {
    "name": "Satisfactory",
    "class_name": "satisfactory",
    "desc": "There is at least one playable where accuracy is between 61% to 80%"
  },
  {
    "name": "Can do better",
    "class_name": "can-do-better",
    "desc": "There is at least one playable where accuracy is between 51% to 60%"
  },
  {
    "name": "Need attention",
    "class_name": "need-attention",
    "desc": "There is at least one playable where accuracy is <= 50%"
  },
  {
    "name": "In progress",
    "class_name": "in-progress",
  }
];

//Mapping for student legend levels
export const mathFactLegendsLevels = [
  {
    "name": "Fluent",
    "class_name": "mastered",
    "desc": "A fact is fluent when it is answered correctly within fluency threshold i.e. 6 sec."
  },
  {
    "name": "In progress",
    "class_name": "in-progress",
    "desc": "A fact stays in progress state until student has mastered it."
  },
  {
    "name": "Yet to practice",
    "class_name": "yet-to-start",
    "desc": "Fact is not yet practiced by the student."
  }
];

export const mathFactSubjectMap = [
  {label: 'Addition', key: 'Addition', active: true},
  {label: 'Subtraction', key: 'Subtraction'},
  {label: 'Multiplication', key: 'Multiplication'},
  {label: 'Division', key: 'Division'},
]

export const STATUS_MAP = {
  MASTERED : 'mastered',
  SATISFACTORY : 'satisfactory',
  CAN_DO_BETTER : 'can-do-better',
  STRUGGLING : 'need-attention',
  INCOMPLETE : 'in-progress',
  YET_TO_START : 'yet-to-start'
}

export const STATUS_MAP_VALUES = {
  MASTERED: "Mastered",
  SATISFACTORY: "Satisfactory",
  CAN_DO_BETTER: "Can do better",
  STRUGGLING: "Need Attention",
  INCOMPLETE: "In Progress",
  YET_TO_START: "Yet to start",
};

export const BOOK_STATUS_MAP = {
  yetToStartStudentIds: 'yet-to-start', 
  inProgressStudentIds: 'in-progress', 
  completedStudentIds: 'completed', 
}

export const STATUS_KEY_MAP = {
  MASTERED : 'MASTERED',
  SATISFACTORY : 'SATISFACTORY',
  CAN_DO_BETTER : 'CAN_DO_BETTER',
  STRUGGLING : 'STRUGGLING',
  INCOMPLETE : 'INCOMPLETE',
  YET_TO_START : 'YET_TO_START'
}

export const totalTopicsPerPage = 3;
export const ACTIVE_SPRINGBOARD_MIN_STUDENTS_COUNT = 5;
export const SPLASH_LEARN_TITLE_CASE = "SplashLearn";
export const SCHOOL_YEAR_START_MONTH = 8;
export const TEACHER_ALLOWED_STUDENTS = 10000;

export const UTM_PARAMS_FOR_ASSIGNMENT_LINK = {
  utm_source: 'assignment_link',
  utm_medium: 'link',
  utm_campaign: 'assignment_link_sharing'
}

export const UTM_PARAMS_FOR_PTL_LINK = {
  utm_source: 'ptl_link',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing',
  referral_type: 'class_referral'
}

export const UTM_PARAMS_ROSTER_LINK = {
  utm_source: 'roster_page',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing',
  referral_type: 'class_referral'
}

export const UTM_PARAMS_ROSTER_PTL = {
  utm_source: 'roster_page',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing'
}

export const UTM_PARAMS_LIVE_KLASS = {
  utm_source: 'live_klass',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing'
}

export const UTM_PARAMS_BOOK_LIBRARY = {
  utm_source: 'book_library',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing'
}

export const NUDGE_REFERRAL_PAGE_MAPPING = { 
  ["home_access"]: {
    code: 9,
    value: "teacher_dashboard_nudge",
  }, 
  ["onboarding"]: {
    code: 9,
    value: "teacher_dashboard_nudge",
  },
  ["assignments"]: {
    code: 10,
    value: "assignment_page_nudge",
  },
  ["review"]: {
    code: 9,
    value: "teacher_dashboard_nudge",
  },
 }

// not being used 
export const UTM_PARAMS_HOME_ACCESS_LINK = {
  utm_source: 'home_access_page',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing',
  referral_type: 'class_referral'
}

export const UTM_PARAMS_HOME_ACCESS_PTL = {
  utm_source: 'home_access_page',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing'
}

export const UTM_PARAMS_SUMMER_LEARNING = {
  utm_source: 'summer_learning_page',
  utm_medium: 'link',
  utm_campaign: 'ptl_link_sharing'
}

export const UTM_PARAMS_FOR_MF_QR_LINK = {
  source: 'ptl_signup',
  utm_source: 'mathfact_worksheet',
  utm_medium: 'qrcode',
  referral_type: 'mf_worksheet_qr',
  referrer_code: 3
}

export const UTM_PARAMS_PARENTS_LETTER_LINK = {
  source: 'ptl_signup',
  utm_source: 'parents_letter',
  utm_medium: 'qrcode',
  utm_campaign: 'parents_letter_sharing',
}

export const GETTING_STARTED_WIDGET_STEPS = {
  addStudentsStep: {
    step_name: "Add Students",
    id: "OBS_2"
  },
  assignPracticeStep: {
    step_name: "Assign Practice",
    id: "OBS_3",
    slug: "assign_practice"
  },
  studentLoginInstructionsStep: {
    step_name: "Get your students to login and play!",
    id: "OBS_7"
  },
  navigateToReportsStep: {
    step_name: "View student progress",
    id: "OBS_8"
  },
  connectParentStep: {
    step_name: "Connect Parents",
    id: "OBS_9"
  },
  verifySchoolInformationStep: {
    step_name: "Verify your school information",
    id: "OBS_10"
  },
  exploreBookLibraryStep: {
    step_name: "Explore Book Library",
    id: "OBS_11",
    slug: "explored_digital_library"
  },
  enableHomeAccess: {
    step_name: "Enable home access",
    id: "OBS_12",
    slug: "enable_home_access"
  }
}

export const CommonMessages = {
  commonErrorMessage: "Something went wrong, please try again"
}

export const CURRICULUM = {
  ccss: 'ccss'
}

export const PremiumPlans = ['teacher_premium_yearly_plan', 'teacher_trial_premium_monthly_plan', 'teacher_premium_yearly_flat_pricing_plan'];

export const InvitationSources = {
  download_letter: "download_letter",
  copy_link: "copy_link",
	single_parent_invite: "single_parent_invite",
  single_parent_flyer: "single_parent_flyer",
  single_parent_invite_reminder: "single_parent_invite_reminder",
  bulk_parent_invite: "bulk_parent_invite"
}

export const ConsumptionSources = {
	download_letter_qr:	"download_letter_qr",
	download_letter_direct:  "download_letter_direct",
  copy_link: "copy_link",
  single_parent_invite:  "single_parent_invite",
  single_parent_flyer: "single_parent_flyer",
  single_parent_invite_reminder:  "single_parent_invite_reminder",
	single_parent_invite_email_drips:	"single_parent_invite_email_drips",
  mf_worksheet_qr: "mf_worksheet_qr",
}

export const ReferrerPages = {
  roster: {
    code: 1,
    value: "roster",
  },
  home_access: {
    code: 2,
    value: "home_access",
  },
  custom_math_fact_assignment_pop_up: {
    code: 3,
    value: "custom_math_fact_assignment_pop_up",
  },
  adaptive_math_fact_assignment_pop_up: {
    code: 4,
    value: "adaptive_math_fact_assignment_pop_up",
  },
  curriculum_assignment_pop_up: {
    code: 5,
    value: "curriculum_assignment_pop_up",
  },
  download_letter_reminder_email: {
    code: 6,
    value: "download_letter_reminder_email",
  },
  teacher_dashboard_nudge: {
    code: 7,
    value: "teacher_dashboard_nudge",
  },
  springboard: {
    code: 8,
    value: "springboard_enrollment",
  },
  assignment_page_nudge: { 
    code: 9, 
    value: "assignment_page_nudge" 
  },
  default: { 
    code: 10, 
    value: "default" 
  },
  springboard_certificate: {
    code: 11,
    value: "springboard_certificate",
  },
  teacher_onboarding_page: { 
    code: 12, 
    value: "teacher_onboarding_page" 
  },
  summer_learning: {
    code: 14,
    value: "summer_learning",
  },
  summer_learning_bundle: {
    code: 15,
    value: "summer_learning_bundle",
  },
  live_klass: {
    code: 16,
    value: "live_klass",
  },
  topic_assignment_pop_up: {
    code: 17,
    value: "topic_assignment_pop_up",
  },
  assignment_page_share_icon: {
    code: 18,
    value: "assignment_page_share_icon"
  },
  book_library: {
    code: 19,
    value: "book_library",
  },
}

export const REFERRER_PAGE_MAP = Object.fromEntries(
  Object.entries(ReferrerPages).map(([key, obj]) => [obj.code.toString(), obj.value])
);

export const ReferralStatus = {
  ACTIVE: "active",
  CONSUMED: "consumed",
  REVOKED: "revoked",
}

export const CLIENT_SIDE_TIMEOUT = 10000

export const EmailConstants = {
  MAX_LENGTH: 100,
  EMAIL_PREFIX_LENGTH: 64,
  REGEX: /^[a-zA-Z0-9!#\$%&'\*\+\/=\?\^_`\{\|}~-]+(\.[a-zA-Z0-9!#\$%&'\*\+\/=\?\^_`\{\|}~-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,})$/
}

export const NAME_REGEX = /^[\s]*[a-z]+[a-z0-9_\s\-\'\.]*$/i

export const NAME_NUMBER_REGEX = /(?=.*[A-Za-z])|(?=.*\d)/;

export const SHOW_MILESTONE_UI_CHANGES_DATE = "2024-09-18T00:00:00";
export const SHOW_MILESTONE_UI_CHANGES_FOR_NONUS_DATE = "2024-11-13T00:00:00";

export const NUMBER_OF_DAYS_TO_SEND_REMINDER = 0;

export const MANDATORY_ACTIVITY_TEXT = "Games and other content will be locked for students during school hours till they complete teacher-assigned activities."

export const MAX_LENGTH_FOR_STUDENT_NAME = 15;

export const BULK_TASK_FROM_PAGE = {
  CURRICULUM: "Curriculum Page",
  STUDENT_REPORT_ASSIGN: "Student report - Assign",
  STUDENT_REPORT_REASSIGN: "Student report - Reassign",
  CLASS_ASSIGNMENT_REASSIGN: "Class Assignment Reassign",
  CLASS_REPORT_REASSIGN: "Class report - Reassign",
  CLASS_REPORT_WHOLE_CLASS: "Class report - Assign to whole class",
  CLASS_REPORT_SINGLE_STUDENT:  "Class report - Assign to single student",
  CLASS_REPORT_GROUP_STUDENTS: "Class report - Assign to group of students",
  CLASS_REPORT_GROUP_VIEW: "Class Report Group View",
  CLASS_CURRICULUM_OLD: "Class Curriculum Old",
  EDIT_ASSIGNMENT: "Edit Assignment"
}